import { addInterceptor, createFetch, RequestInitExt, FetchResponseType } from 'onefetch'

const BaseUrl = process.env.REACT_APP_BASE_URL //环境变量URL
const Timeout = 6000 //接口超时时间

export interface RequestObj<T> {
  data: T
  method: string
  url: string
  headers?: Record<string, string>
  responseType?: FetchResponseType //text json blob arrayBuffer
  credentials?: RequestCredentials | undefined
  cache?: RequestCache
  noContentType?: boolean | undefined
}

addInterceptor({
  request(req: RequestInitExt): RequestInitExt {
    return req
  },
  response(res: any): any {
    return res.data
  },
  error(err: any) {
    return err
  },
})

export default function request(obj: RequestObj<any>): Promise<any> {
  const { url, ...options } = obj
  const httpUrl = url.startsWith('http') ? url : BaseUrl + url
  return createFetch(httpUrl, options, Timeout)
}
