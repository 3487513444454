import request from '../utils/request'

export function getAlbum(data: any) {
  return request({
    url: 'image-web-api/api/image/getByImageId',
    method: 'POST',
    data,
  })
}

export function getAlbumFiles(data: any) {
  return request({
    url: 'image-web-api/api/image/findFileListByImageId',
    method: 'POST',
    data,
  })
}

export function downloadAlbumFile(data: any) {
  return request({
    url: data,
    method: 'GET',
    data,
    responseType: 'blob',
    cache: 'no-store',
    noContentType: true,
  })
}
