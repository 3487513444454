import PhotoBank from '../pages/photo-bank'
import Home from '../pages/home'

export interface RouteMap {
  path: string
  element: JSX.Element
}

const routeList: RouteMap[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/photo-bank',
    element: <PhotoBank />,
  },
]

export default routeList
