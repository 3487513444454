import { useEffect } from 'react'
import styles from './index.module.css'

console.log(styles)

function Home() {
  useEffect(() => {
    console.log('home page is mounted')
  }, [])

  return <div></div>
}

export default Home
