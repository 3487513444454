import styles from "./index.module.css";
import React, { useEffect, useState, useMemo } from "react";
import GouChecked from "../../assets/gou-checked.png";
import {
  getAlbum,
  getAlbumFiles,
  downloadAlbumFile,
} from "../../api/photo-bank";
import { useSearchParams } from "react-router-dom";
import { resizeImage } from "../../utils/image";
import { message } from "antd";

function downloadFileByUrl(src: string = ""): void {
  const aEl = document.createElement("a") as HTMLAnchorElement;
  aEl.style.display = "none";
  aEl.href = src;
  aEl.download = "";
  document.body.appendChild(aEl);
  aEl.click();
}

interface ImgItemProps {
  item: any;
  index: number;
  pIndex: number;
  onChange: (event: React.MouseEvent, pIndex: number, index: number) => void;
}

interface ImageItemObj {
  proName: string;
  files: Array<FileObj>;
}

interface FileObj {
  checked: boolean;
  filename: string;
  thumbnail: string;
  longurl: string;
}

const ImgItem = function ({
  item,
  pIndex,
  index,
  onChange,
}: ImgItemProps): JSX.Element {
  console.log(`ImgItem ${index} render`);

  return (
    <div
      className={styles["img-item"]}
      onClick={(e) => onChange(e, pIndex, index)}
    >
      <img src={resizeImage(item.thumbnail, 260)} />
      <div className={styles["gou-icon"]}>
        {item.checked && <img src={GouChecked} />}
      </div>
    </div>
  );
};

export default function PhotoList() {
  const [imgObjList, setImgObjList] = useState<ImageItemObj[]>([]);
  const [isCheckedAllImg, setIsCheckedAllImg] = useState(false);
  const [title, setTitle] = useState("");

  const fileCheckedCount = useMemo(() => {
    let sum: number = 0;
    imgObjList.forEach((el) => {
      sum += el.files.filter((x) => x.checked).length;
    });

    return sum;
  }, [imgObjList]);

  const [routeParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const data = atob(routeParams.get("id") || ""); // base64解码

    getAlbum({ data })
      .then(async (res) => {
        const {
          productName,
          baseProductTypeStatList = [],
          imageId,
        } = res.data || {};

        const list = await Promise.all(
          baseProductTypeStatList.map((el: any) =>
            getAlbumFiles({
              data: {
                imageId,
                baseProductType: el.baseProductType,
              },
            })
          )
        );

        if (
          list.length === 0 ||
          list.some((el) => !Array.isArray(el.data) || el.data.length === 0)
        ) {
          messageApi.info("暂无影像馆数据");
          return;
        }

        const newArr: ImageItemObj[] = list
          .map((el) => el.data || [])
          .map((el, index) => {
            return {
              proName: baseProductTypeStatList[index].proName,
              files: el.map((f: any) => ({
                checked: false,
                filename: f.name,
                thumbnail: f.coverUrl || f.url.split("?")[0],
                longurl: f.url,
              })),
            } as ImageItemObj;
          });

        if (productName) setTitle(productName);
        setImgObjList(newArr);
      })
      .catch((err) => console.log(err));
  }, [routeParams]);

  const onChange = (event: React.MouseEvent, pIndex: number, index: number) => {
    event.stopPropagation();
    const newArr = [...imgObjList];
    newArr[pIndex].files[index].checked = !newArr[pIndex].files[index].checked;
    setImgObjList(newArr);
  };

  const downloadImg = async () => {
    try {
      const arr: FileObj[] = [];
      imgObjList.forEach((el) => {
        arr.push(...el.files.filter((x) => x.checked));
      });

      if (arr.length === 0) {
        messageApi.info("请选择要下载的图片");
        return;
      }

      const expiresArr = arr.filter((el) => {
        const urlArr = el.longurl.split("?");
        if (urlArr.length === 1) return false;

        const expiresTime = new URLSearchParams(urlArr[1]).get("Expires");
        if (!expiresTime) return false;

        return Date.now() > new Date(Number(expiresTime) * 1000).getTime();
      });

      console.log("过期照片数组：", expiresArr);

      const messageKey = "updatable";

      for (let index = 0; index < arr.length; index++) {
        messageApi.open({
          key: messageKey,
          type: "loading",
          content: `正在下载照片，${index + 1}/${
            arr.length - expiresArr.length
          }`,
          duration: 0,
        });

        const downloadUrl = expiresArr.includes(arr[index])
          ? arr[index].thumbnail
          : arr[index].longurl;

        if (downloadUrl) {
          const blob = await downloadAlbumFile(downloadUrl);
          const blobUrl = URL.createObjectURL(blob);
          downloadFileByUrl(blobUrl);
          URL.revokeObjectURL(blobUrl);
        }
      }

      messageApi.open({
        key: messageKey,
        type: "success",
        content: "下载完成",
        duration: 2,
      });
    } catch (err) {
      messageApi.destroy();
      messageApi.open({
        type: "error",
        content: "下载失败",
        duration: 2,
      });

      console.log(err);
    }
  };

  const chooseAllImage = () => {
    setIsCheckedAllImg(!isCheckedAllImg);

    const copyImgObjList = [...imgObjList];
    copyImgObjList.forEach((el) => {
      el.files.forEach((x) => (x.checked = !isCheckedAllImg));
    });

    setImgObjList(copyImgObjList);
  };

  const imgListTag = imgObjList.map((el, pIndex) => {
    return (
      <React.Fragment key={el.proName}>
        <div className={styles["item-title"]}>
          {el.proName}
          <span className={styles["sub-num"]}>({el.files.length}张)</span>
        </div>

        <div className={styles["wrapper"]}>
          {el.files.map((x, index) => {
            return (
              <ImgItem
                item={x}
                onChange={onChange}
                index={index}
                key={x.filename}
                pIndex={pIndex}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  });

  return (
    <>
      {contextHolder}

      <div className={styles["photo-bank"]}>
        <div className={styles["top-bar"]}>
          <div className={styles["warning-tips"]}>
            温馨提示：如果照片没有封面，依然可以下载
            <br />
            请将浏览器“下载前询问每个文件保存位置”设置项关闭
          </div>

          <div className={styles["tool-bar"]}>
            <span className={styles["title"]}>{title}</span>

            <div className={styles["info-right"]}>
              <span className={styles["checked-num"]}>
                已选择{fileCheckedCount}张
              </span>

              <span
                className={styles["choose-all-btn"]}
                onClick={chooseAllImage}
              >
                {!isCheckedAllImg ? "全选" : "取消全选"}
              </span>

              <span
                className={styles["download-btn"]}
                onClick={() => downloadImg()}
              >
                立即下载
              </span>
            </div>
          </div>
        </div>

        {imgListTag}
      </div>
    </>
  );
}
