import './App.css'
import { Routes, Route } from 'react-router-dom'
import React from 'react'
import routes from './router'

function App() {
  return (
    <React.Fragment>
      <Routes>{renderRoutes()}</Routes>
    </React.Fragment>
  )
}

function renderRoutes(): JSX.Element[] {
  return routes.map(({ path, element }) => <Route path={path} element={element} key={path} />)
}

export default App
